<template>
    <div class="checkbox-component" v-bind:class="{disabled: isDisabled, 'focus-highlight': hover, 'toggle-checkbox': toggleCheck, 'toggled': value, small: small}">
        <buttonc
            type="checkbox no-hover no-background"
            :icon="value && !toggleCheck ? 'fa-check-circle' : 'fa-circle'"
            :styleIcon="{color: value ? colorCheckedComp : colorUncheckedComp}"
            @Update="Update($event)"
            @click="Click($event)"
            v-on:mousedown="FocusElement()"
            v-on:focus="hover = true"
            v-on:blur="Blur"
        />
    </div>
</template>

<script>
    export default {
        name: "checkbox",
        data(){
            return {
                hover: false,
            }
        },
        props: {
            value: [Boolean, String, Number],
            data: Object,
            disabled: [Boolean, Function],
            small: [Boolean],
            colorChecked: [String, Function],
            colorUnchecked: [String, Function],
            toggleCheck: [Boolean]
        },
        computed: {
            isDisabled(){
                if(!this.$helpers.isFunction(this.disabled)) return this.disabled;
                return this.disabled(this);
            },
            colorCheckedComp(){
                if(!this.$helpers.isFunction(this.colorChecked)) return this.colorChecked;
                return this.colorChecked(this);
            },
            colorUncheckedComp(){
                if(!this.$helpers.isFunction(this.colorUnchecked)) return this.colorUnchecked;
                return this.colorUnchecked(this);
            },
        },
        methods: {
            FocusElement(){
                let input = this.$el.querySelector('input');
                if(input) input.focus();
            },
            Blur($event){
                this.hover = false;
            },
            Update($event){
                /*if(typeof this.value === 'number') this.value === 0 ? newVal = 1 : newVal = 0;
                else if(typeof this.value === 'boolean') this.value === false ? newVal = true : newVal = false;
                else if(typeof this.value === 'string') this.value === 'false' ? newVal = 'true' : newVal = 'false';*/
                this.$emit('Update', $event, this.value, this.data);
            },
            Click($event){
                let newVal = !this.value;
                //let newVal = false;
                /*if(typeof this.value === 'number') this.value === 0 ? newVal = 1 : newVal = 0;
                else if(typeof this.value === 'boolean') this.value === false ? newVal = true : newVal = false;
                else if(typeof this.value === 'string') this.value === 'false' ? newVal = 'true' : newVal = 'false';*/
                this.$emit('Update', $event, newVal, this.data);
            },
        },
    }
</script>

<style scoped>
    .checkbox-component{
        cursor: pointer;
        position: relative;
    }
    .checkbox-component input{
        opacity: 0;
        z-index: -1;
    }

    .checkbox-component:hover,.checkbox-component.hover, .checkbox-component .button.focus-highlight{
        filter: brightness(120%) !important;
    }

    .checkbox-component.toggle-checkbox{
        height: 20px;
        width: 30px;
        background: var(--contrast-4);
        padding: 3px;
        border-radius: 30px;
    }

    .checkbox-component.toggle-checkbox.small{
        height: 13px;
        width: 22px;
        background: var(--contrast-4);
        padding: 3px;
        border-radius: 30px;
    }
    .checkbox-component.toggle-checkbox .button{
        height: fit-content;
        font-size: 15px;
        left: -6px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
    }
    .checkbox-component.toggle-checkbox.small .button{
        height: fit-content;
        font-size: 9px;
        left: -6px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
    }
    .checkbox-component.toggle-checkbox.toggled .button{
        left: 3px;
    }
    .checkbox-component.toggle-checkbox.toggled{
        background: var(--ml);
    }
    .checkbox-component.toggle-checkbox.small:not(.toggled){
        opacity: .3;
    }
    .checkbox-component.toggle-checkbox.small.toggled{
        background: var(--contrast-4);
    }
</style>
